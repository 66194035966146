<template>
    <icon-base :size="size" :color="color" viewBox="0 0 200 200">
        <g>
            <path class="st0" d="M193.5,60.8L86.2,168.3c-4.2,3.9-9.4,6.2-14.9,6.2c-5.2,0-10.7-2.3-14.6-6.2L6.5,118.1   c-8.1-8.1-8.1-21.4,0-29.5s21.1-8.1,29.2,0l35.6,35.6L164,31.6c8.1-8.1,21.4-8.1,29.5,0S201.6,52.7,193.5,60.8z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconCheckMark',
    components: {
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20,
        },
        color:{
            type: String,
            default: ''
        }
    }
}
</script>

<style>

</style>